
import { defineComponent, ref, watch, computed } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { StudentClassAssignment } from "@/core/models/StudentClassAssignment"
import ApiService from "@/core/services/ApiService"
import { AxiosError } from "axios"

export default defineComponent({
  name: "class-student-component",
  components: {},
  props: ["schoolClassId", "className", "classNumber"],
  setup(props) {
    const students = ref<StudentClassAssignment[]>([] as StudentClassAssignment[])
    const list1 = computed(() => props.classNumber)
    const list2 = computed(() => props.className)

    watch(
      () => props.schoolClassId,
      async newValue => {
        if (newValue) {
          await getClassStudentList(newValue)
        } else {
          students.value = []
        }
      }
    )

    const getClassStudentList = async schoolClassId => {
      const { data } = await ApiService.get("student-assignment/" + schoolClassId)
      students.value = data
    }

    const addStudentToSchoolClass = async (studentId, schoolClassId) => {
      await ApiService.post("student-assignment/add", { studentId, schoolClassId }).catch(
        (e: AxiosError) => {
          console.log(e.message)
        }
      )
    }

    const removeStudentToSchoolClass = async (studentId, schoolClassId) => {
      await ApiService.post("student-assignment/remove", { studentId, schoolClassId }).catch(
        (e: AxiosError) => {
          console.log(e.message)
        }
      )
    }

    // Element-Plus el-transfer BEGIN
    const transformedData = computed(() => {
      return students.value.map(student => {
        return {
          key: student.studentId,
          label: student.fullName.toLocaleUpperCase("tr-TR"),
          initial: student.fullName.toUpperCase(),
          isAssigned: student.isAssigned,
        }
      })
    })

    const leftValue = computed(() => {
      return students.value.filter(student => student.isAssigned).map(student => student.studentId)
    })

    const handleChange = async (value, direction, movedKeys) => {
      if (direction === "right") {
        for (const studentId of movedKeys) {
          await addStudentToSchoolClass(studentId, props.schoolClassId)
        }
      } else if (direction === "left") {
        for (const studentId of movedKeys) {
          await removeStudentToSchoolClass(studentId, props.schoolClassId)
        }
      }
      await getClassStudentList(props.schoolClassId)
    }

    const filterMethod = (query, item) => {
      return item.initial.includes(query.toUpperCase())
    }

    // Element-Plus el-transfer END

    return {
      Edit,
      students,
      transformedData,
      leftValue,
      filterMethod,
      handleChange,
      list1,
      list2,
    }
  },
})
