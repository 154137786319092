import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_transfer = _resolveComponent("el-transfer")!
  const _component_el_div = _resolveComponent("el-div")!

  return (_openBlock(), _createBlock(_component_el_div, { style: {"text-align":"center"} }, {
    default: _withCtx(() => [
      _createVNode(_component_el_transfer, {
        modelValue: _ctx.leftValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.leftValue) = $event)),
        filterable: "",
        "filter-method": _ctx.filterMethod,
        "filter-placeholder": "Öğrenci Arama",
        data: _ctx.transformedData,
        titles: [
        _ctx.list1 ? `${_ctx.list1} Bekleyen Öğrenciler` : 'Öğrenci',
        _ctx.list2 ? `${_ctx.list2} Şubesi` : 'Şube',
      ],
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "filter-method", "data", "titles", "onChange"])
    ]),
    _: 1
  }))
}