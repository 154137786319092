
import ApiService from "@/core/services/ApiService"

import { useRoute, useRouter } from "vue-router"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import ClassStudentComponent from "@/components/education/definitions/class-student/class-student.vue"
import { SchoolClass } from "@/core/models/SchoolClass"
import { SelectModel, SelectModelInt } from "@/core/models/SelectModel"
import { dateForm, DateFormatTypes } from "@/core/helpers/dateformat"
import { AxiosRequestConfig } from "axios"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"

export default defineComponent({
  name: "class-student",
  components: { ClassStudentComponent },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const serviceTreeSelectProps = {
      label: "name",
      value: "id",
      children: "childs",
      disabled: data => {
        return !data.isService
      },
    }

    const schoolClassList = ref<SchoolClass[]>([] as SchoolClass[])
    const isLoading = ref(false)
    const activeSchoolClassId = ref<string | undefined>()
    const selectedSchoolClass = ref<string | undefined>()
    const selectedSchoolNumber = ref<string | undefined>()

    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const activeSemesterId = ref<string>()
    const activeTimePeriodId = ref<string>()
    const activeServiceId = ref<string>()

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const semesterList = ref<SelectModel[]>([])
    const timePeriodList = ref<SelectModel[]>([])
    const serviceList = ref<ServiceHierarchy[]>([])

    const statusList = ref<SelectModelInt[]>([
      {
        id: 0,
        name: "Boşta",
      } as SelectModelInt,
      {
        id: 1,
        name: "Açık",
      } as SelectModelInt,
      {
        id: 2,
        name: "Kapalı",
      } as SelectModelInt,
    ])

    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isSemesterSelectLoading = ref(false)
    const isTimePeriodSelectLoading = ref(false)
    const isServiceSelectLoading = ref(false)

    watch(
      () => route.params.school_class_id,
      newValue => {
        activeSchoolClassId.value = newValue as string
      }
    )

    async function getSchoolClassList() {
      if (!activeBusinessId.value) return
      const config = {
        params: {},
      } as AxiosRequestConfig

      if (activeBusinessId.value) config.params.businessId = activeBusinessId.value
      if (activeBranchId.value) config.params.branchId = activeBranchId.value
      if (activeSemesterId.value) config.params.semesterId = activeSemesterId.value
      if (activeTimePeriodId.value) config.params.timePeriodId = activeTimePeriodId.value
      if (activeServiceId.value) config.params.serviceId = activeServiceId.value
      if (activeSchoolClassId.value) config.params.classRoomId = activeSchoolClassId.value

      isLoading.value = true
      const { data } = await ApiService.query("school-class", config)
      schoolClassList.value = data
      isLoading.value = false
    }

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      activeBusinessId.value = undefined
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        onBusinessChange(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (branchList.value.length == 1) {
        activeBranchId.value = branchList.value[0].id
      }
      isBranchSelectLoading.value = false
    }
    const getSemesterList = async businessId => {
      isSemesterSelectLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data
      if (semesterList.value.length == 1) {
        activeSemesterId.value = semesterList.value[0].id
      }
      isSemesterSelectLoading.value = false
    }

    const getTimePeriodList = async businessId => {
      isTimePeriodSelectLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data
      if (timePeriodList.value.length == 1) {
        activeTimePeriodId.value = timePeriodList.value[0].id
      }
      isTimePeriodSelectLoading.value = false
    }

    const getServiceList = async businessId => {
      isServiceSelectLoading.value = true
      activeServiceId.value = undefined
      const query = {
        params: {
          isWithService: true,
          onlyEducation: true,
        },
      }

      const { data } = await ApiService.query(
        `service-category/business/${businessId}/childs`,
        query
      )
      data.map(item => {
        item.isEducation = true
        return item
      })
      serviceList.value = data
      isServiceSelectLoading.value = false
    }

    const handleClassStudentDialogClose = () => {
      router.push({ name: "class-student-list" })
    }

    onMounted(async () => {
      activeSchoolClassId.value = ""
      if (route.params.school_class_id) handleClassStudentDialogClose()
      await getBusinessList()
    })

    const onBusinessChange = async businessId => {
      activeBranchId.value = undefined
      activeSemesterId.value = undefined
      activeTimePeriodId.value = undefined
      activeServiceId.value = undefined
      activeSchoolClassId.value = undefined

      branchList.value = []
      semesterList.value = []
      timePeriodList.value = []
      serviceList.value = []
      schoolClassList.value = []

      if (businessId) {
        await getBranchList(businessId)
        await getSemesterList(businessId)
        await getTimePeriodList(businessId)
        await getServiceList(businessId)
      }

      await getSchoolClassList()
      selectedSchoolClass.value = ""
      selectedSchoolNumber.value = ""
    }

    const onBranchChange = async () => {
      handleClassStudentDialogClose()
      if (semesterList.value.length == 1) {
        activeSemesterId.value = semesterList.value[0].id
      } else {
        activeSemesterId.value = ""
      }
      if (timePeriodList.value.length == 1) {
        activeTimePeriodId.value = timePeriodList.value[0].id
      } else {
        activeTimePeriodId.value = ""
      }
      activeServiceId.value = ""
      activeSchoolClassId.value = ""
      selectedSchoolClass.value = ""
      selectedSchoolNumber.value = ""
      await getSchoolClassList()
    }

    const onSemesterChange = async () => {
      handleClassStudentDialogClose()
      if (timePeriodList.value.length == 1) {
        activeTimePeriodId.value = timePeriodList.value[0].id
      } else {
        activeTimePeriodId.value = ""
      }
      activeServiceId.value = ""
      activeSchoolClassId.value = ""
      selectedSchoolClass.value = ""
      selectedSchoolNumber.value = ""
      await getSchoolClassList()
    }

    const onSelectChange = async () => {
      handleClassStudentDialogClose()
      await getSchoolClassList()
    }

    const onSelectChangeClass = async () => {
      await getSchoolClassList()
      if (schoolClassList.value.length > 0) {
        redirectToClassStudentComponent(schoolClassList.value[0].id)
      }
    }

    const redirectToClassStudentComponent = schoolClassId => {
      router.push({
        name: "class-student-assign",
        params: { school_class_id: schoolClassId },
      })
      const foundSchoolClass = schoolClassList.value.find(item => item.id === schoolClassId)
      if (foundSchoolClass) {
        selectedSchoolClass.value = foundSchoolClass.classRoomName
        selectedSchoolNumber.value = foundSchoolClass.serviceName
      }
    }

    return {
      Edit,
      isLoading,
      dateForm,
      onSelectChange,
      getBranchList,
      getSemesterList,
      getTimePeriodList,
      getServiceList,
      getSchoolClassList,
      onBranchChange,
      onSemesterChange,
      onBusinessChange,
      onSelectChangeClass,
      handleClassStudentDialogClose,
      redirectToClassStudentComponent,
      activeSchoolClassId,
      businessList,
      schoolClassList,
      branchList,
      semesterList,
      timePeriodList,
      serviceList,
      statusList,
      activeBusinessId,
      activeBranchId,
      activeSemesterId,
      activeTimePeriodId,
      activeServiceId,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isSemesterSelectLoading,
      isTimePeriodSelectLoading,
      isServiceSelectLoading,
      serviceTreeSelectProps,
      DateFormatTypes,
      selectedSchoolClass,
      selectedSchoolNumber,
    }
  },
})
